:root {
  --primary-color: rgb(102, 112, 111);
  --secondary-color: rgb(3, 46, 52);
  --border-color: rgb(3, 46, 52);
  --theme-second-color: rgb(3, 61, 70);

  --single-project-color: rgb(2, 27, 33);
  --contentColor: white;
  --hover-color: rgb(210, 213, 175);
  --firstLevFontSize: 24px;
  --secLevFontSize: 16px;
  --genFontSize: 12px;
  --marginForLeftAndRightNavInHeader: 3rem;
  --header-font-size: 1.7rem;
  --fontSecondLevelforResDevices: 3rem;
  --fontThirdLevelforResDevices: 2.7rem;
  --fontFourthLevelforResDevices: 2rem;
}

body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background: var(--primary-color);
  color: var(--contentColor);
  height: 100%;
  width: fit-content;
  padding: 0;
  box-sizing: border-box;
}

/* .layoutContainer {
  border: 5px yellow solid;
} */
.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0.2rem;
  width: 100%;
  background-color: var(--secondary-color);
  color: var(--contentColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px 5px rgb(21, 6, 32);
  font-weight: bold;
}

.rightNavList {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 36rem;
}

.rightNavList .nav-item a {
  color: #ffffff;
}

.nav-item .active {
  padding-bottom: 6px;
  border-bottom: 3px solid #ffffff;
}
.rightNavList .nav-item a:hover {
  padding-bottom: 6px;
  border-bottom: 3px solid #ffffff;
  cursor: pointer;
}

/* a,
a:visited {
  color: white;
}
.rightNavList li a:hover,
.rightNavList li a.active {
  cursor: pointer;
  color: rgb(10, 7, 60);
  padding-bottom: 5px;
  border-bottom: 3px solid rgb(10, 7, 60);
} */

.rightNavIcon {
  display: none;
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--contentColor);
  margin-right: var(--marginForLeftAndRightNavInHeader);
}

.leftSign {
  margin-left: var(--marginForLeftAndRightNavInHeader);
}

.backdropNewSideMenu {
  position: fixed;
  top: 0;

  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(5, 16, 16); */
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  overflow-y: hidden;
}

.menuList {
  margin-top: 35rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.menuList li {
  font-size: 4rem;
}

.menuList li:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
}

.MenuIcon {
  cursor: pointer;
  scale: 4;
  transition: all 900ms;
  margin-right: 1.5rem;
}

.MenuIcon:active {
  scale: 1.5;
}

/*This down below is for Home Page*/
.homePageContainer {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
  margin: 10.4rem 12rem;
}
.leftSideHome {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid black; */
}
.introGreeting h1,
.introGreeting h3,
.introGreeting h4 {
  margin: 0;
}

.introGreeting h1 {
  font-size: 3.2rem;
  background-color: var(--theme-second-color);
  width: 23rem;
  text-align: left;
  color: white;
  display: block;
  padding: 0.2rem;
  font-weight: bold;
}
.introGreeting h3 {
  font-size: 1.5rem;
}

.introPhoto {
  width: 45vw;
  /* border: 1px solid yellow; */
  height: auto;
}
.introPhoto img {
  width: 50%;
  height: 50%;
  border-radius: 20%;
  border: 1.5px solid var(--border-color);
}

.rightSideHome {
  width: 100%;
  min-height: 95%;
  box-sizing: border-box;
  background-color: var(--theme-second-color);
  box-shadow: 0px 1px 30px 0px rgb(16, 15, 44);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.rightSideHome p {
  margin-block-start: 0.6rem;
  margin-block-end: 0.6rem;
}
/*This down below is for ABOUT component*/
.aboutSection {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  font-size: var(--header-font-size);
  text-align: center;
}

/* .aboutHeader h1 {
  text-shadow: 16px -12px 9px white;
} */

.quoteAbout {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  padding: 0.1rem 1rem;
  background-color: var(--theme-second-color);
  border-radius: 6px;
  margin: 0 2rem;
  gap: 0;
}

.quoteAbout p {
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.aboutMain {
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0rem;
}

.aboutPhoto {
  /* border: 1px solid black; */
  width: 50%;
}
.aboutPhoto img {
  width: 65%;

  border-radius: 10%;
  border: 1px var(--border-color) solid;
}

.aboutParagraphSection {
  /* border: 1px solid black; */
  width: 100%;
  min-height: 95%;
  box-sizing: border-box;
  padding: 0 4rem;
  margin-top: 0rem;
  margin-left: 5rem;
}
.aboutParagraphSection p {
  font-size: 1.1rem;
}

/* This down below is for Projects section*/

.ProjectSection {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectsHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  font-size: var(--header-font-size);
  text-align: center;
  font-weight: bold;
}

.projectsIntro {
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
}

.projectsIconPhoto {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 1px solid; */
}

.projectsIconPhoto img {
  width: 45%;
  border: 1px var(--border-color) solid;
  border-radius: 10%;
  /* border-top-right-radius: 10%;
  border-bottom-right-radius: 10%; */
}

.projectsIntroPar {
  width: 45rem;
  min-height: 95%;
  box-sizing: border-box;
  padding: 0 2rem;

  background-color: var(--theme-second-color);
}

.projectsIntroPar p {
  font-size: 1rem;
}

.projectsList {
  margin-top: 5rem;
  display: flex;
  /* margin-bottom: 5rem; */

  justify-content: center;
  align-items: center;
}

.projectsList ul {
  /* list-style: none; */

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-content: center;
  place-items: center;
  padding-inline-start: 0px;
}

/*This down below is for Single Project*/

.single-project-container {
  /* border-color: white white white white; */
  box-sizing: border-box;
  width: 52%;

  min-height: 95%;
  /* background-color: rgb(10, 7, 60); */
  background-color: var(--single-project-color);
  transition: all 0.5s;

  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.single-project-container img {
  width: 100%;
}

.single-project-details {
  width: 100%;
  box-sizing: border-box;
  min-height: 95%;
  padding: 1rem 0.7rem;
  /* display: flex;
  flex-direction: column; */
}

.single-project-par {
  /* border: 1px solid red; */
  box-sizing: border-box;
  width: 100%;
  /* max-width: 95%; */
  min-height: 95%;
  box-sizing: border-box;
  word-wrap: normal;
  /* word-break: break-all; */
}

.single-project-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 95%;
}

.single-project-actions button {
  color: var(--theme-second-color);
  border-radius: 6px;
  height: 2rem;
}

.single-project-actions button:hover {
  cursor: pointer;
  background-color: var(--hover-color);
}

.single-project-container:hover {
  transform: scale(1.08);
}

/* This down below is for Contact Section  */

.contactMeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.contactMeHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  font-size: var(--header-font-size);
  text-align: center;
  margin-top: 7rem;
}

.contactMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin: 0 2rem;
}

.leftSideContactMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
}

.contactInfo p {
  font-size: 1.1rem;
}

.rightSideContactMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.rightSideContactMain form {
  /* border: 1px solid black; */
  width: 38rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* padding: 2rem; */
}

.formNameAndEmail {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}

.formNameAndEmail input {
  width: 18rem;
}

.formSubjectAndMessage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rightSideContactMain form input {
  border-radius: 6px;
  padding: 0.5rem;
}

.rightSideContactMain form textarea {
  height: 10rem;
  border-radius: 6px;
  padding: 0.5rem;
}

.rightSideContactMain form input,
.rightSideContactMain form textarea {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 1rem;
}

.formActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.formActions button {
  border-radius: 6px;
  padding: 0.4rem;
  width: 15%;
  background-color: var(--theme-second-color);
  color: white;
  font-size: 1rem;
}

.formActions button:hover {
  background-color: rgb(1, 130, 27);
  cursor: pointer;
}

.contactIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}

.githubIcon,
.linkedInIcon {
  scale: 1.5;
}

.githubIcon:hover,
.linkedInIcon:hover {
  scale: 1.7;
  fill: var(--theme-second-color);
  cursor: pointer;
}

a {
  color: var(--theme-second-color);
}

.footerSection {
  background-color: var(--secondary-color);
  height: 4rem;

  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 5px 5px rgb(21, 6, 32);
}

/*This down below is for modal */
.modal {
  width: 32vw;

  box-sizing: border-box;
  /* width: clamp(50%, 700px, 90%); */

  /* height: min(25%, 400px); */
  margin: auto;
  min-width: 40%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.modalHeader {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 12px 12px 0 0;
  height: 30%;
}

.modalBody {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  align-items: center;
  border-radius: 0 0 12px 12px;
}

.modalBody p {
  padding: 0 1rem;
}
.modalActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.modalActions button {
  border-radius: 6px;
  width: 4rem;

  min-height: 95%;
  box-sizing: border-box;
  padding: 0.5rem;
}

.error {
  background-color: rgb(99, 10, 10);
}

.success {
  background-color: rgb(4, 70, 32);
}

.modalActions button:hover {
  cursor: pointer;
  background-color: rgb(210, 213, 175);
}
/*
background-color: rgb(5, 236, 186);
background-color: rgb(4, 57, 46);
*/

.closeIcon {
  position: fixed;
  top: 0;
  right: 0;
  scale: 2.5;
  margin-top: 3rem;
  margin-right: 4rem;
}

.closeIcon:hover {
  scale: 0.9;
}
@media screen and (max-width: 480px) {
  .mainHeader {
    height: 8rem;
  }

  .leftSign h3 {
    font-size: 3rem;
    margin-left: 0.5rem;
  }
  .homePageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    /* border: 1px solid black; */
    margin-top: 15rem;
    margin-bottom: 42rem;
    margin-left: 6rem;
    margin-right: 6rem;
    padding: 2rem 0;
  }

  .rightSideHome {
    display: none;
  }

  .leftSideHome {
    width: 100%;
    margin-top: 22rem;
    /* border: 1px solid green; */
  }
  .introPhoto {
    width: 45rem;
    /* border: 1px solid yellow; */
    margin: auto;
    margin-top: 4rem;
  }
  .introPhoto img {
    width: 100%;
    height: auto;
  }

  .introGreeting {
    margin: auto;
  }
  .introGreeting h1 {
    font-size: 6rem;
    background-color: var(--theme-second-color);
    width: 46rem;
    text-align: center;
    color: white;
  }
  .introGreeting h3 {
    font-size: 3.5rem;
  }
  .introGreeting h4 {
    font-size: 2.5rem;
  }

  .aboutHeader h1 {
    font-size: 6rem;
  }

  .quoteAbout {
    margin: 0 2rem;
  }

  .quoteAbout p {
    font-size: 3rem;
  }

  .aboutMain {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutPhoto {
    display: none;
  }

  .aboutParagraphSection {
    margin-left: 0;
  }
  .aboutParagraphSection p {
    font-size: var(--fontSecondLevelforResDevices);
    font-weight: bold;
  }

  /*Projects section*/

  .ProjectSection {
    margin-bottom: 10rem;
  }

  .projectsHeader {
    margin-top: 6rem;
  }
  .projectsHeader h1 {
    font-size: 6rem;
  }

  .projectsIconPhoto {
    width: 80%;
  }

  .projectsIconPhoto img {
    width: 55%;
  }

  .projectsIntroPar {
    padding: 0 2rem;
  }

  .projectsIntroPar p {
    font-size: 2.8rem;
  }

  .projectsList ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 6rem;
    justify-content: center;
    place-items: center;
  }

  /*single project*/
  .single-project-container {
    width: 75%;
    font-size: 2.4rem;
  }
  .single-project-container img {
    width: 100%;
  }

  .single-project-details {
    padding: 2rem 2rem;
  }

  .single-project-par p {
    font-size: var(--fontThirdLevelforResDevices);
  }
  .single-project-actions {
    gap: 3rem;
  }

  .single-project-actions button {
    height: 100%;
    padding: 1rem 1.5rem;
    font-size: 2.4rem;
  }

  /*contact section*/

  .contactMeSection {
    margin-top: 0;
    height: 100%;
    margin-bottom: 5rem;
  }

  .contactMeHeader {
    margin-top: 11rem;
  }
  .contactMeHeader h1 {
    font-size: 6rem;
  }

  .contactMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
  }

  .contactInfo p {
    font-size: var(--fontSecondLevelforResDevices);
    margin-bottom: 5rem;
  }

  .leftSideContactMain {
    width: 80%;
    margin: 0 4rem;
  }

  .contactIcons {
    gap: 7rem;
    margin-bottom: 2rem;
  }
  .linkedInIcon,
  .githubIcon {
    scale: 3;
  }

  /*contact form*/

  .formNameAndEmail {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .formNameAndEmail input {
    width: 100%;
  }

  .formSubjectAndMessage {
    gap: 2rem;
  }
  .formSubjectAndMessage input,
  .formSubjectAndMessage textarea {
    width: 100%;
  }

  .rightSideContactMain form input,
  .rightSideContactMain form textarea {
    font-size: var(--fontSecondLevelforResDevices);
    width: 100%;
  }

  .rightSideContactMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem;
  }

  .rightSideContactMain form {
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding: 2rem; */
  }

  .rightSideContactMain form input {
    padding: 1rem;
  }

  .rightSideContactMain form textarea {
    height: 20rem;
    width: 100%;
    padding: 0.5rem;
  }

  .formActions {
    margin-bottom: 5rem;
  }

  .formActions button {
    width: fit-content;
    padding: 1.5rem;
    font-size: var(--fontSecondLevelforResDevices);
  }

  .footerSection {
    height: 9rem;
    text-align: center;
    font-size: var(--fontFourthLevelforResDevices);
  }

  .footerSection span {
    padding: 1rem;
  }

  /*This is for modal*/

  .modal {
    width: 80%;
    padding: 1rem 0;
    box-sizing: border-box;
    /* width: clamp(50%, 700px, 90%); */

    /* height: min(25%, 400px); */
  }

  .modalHeader h3 {
    font-size: var(--fontSecondLevelforResDevices);
  }
  .modalBody p {
    font-size: var(--fontSecondLevelforResDevices);
    padding: 0 1.5rem;
    text-align: center;
  }

  .modalActions button {
    width: 9rem;

    font-size: 2.4rem;
    padding: 0.5rem;
  }

  .modalActions {
    margin-bottom: 1rem;
  }

  .titleNav {
    display: none;
  }

  .rightNavIcon {
    display: block;
  }

  .menuList {
    list-style: none;
  }

  .menuList li {
    font-size: 5rem;
  }
  .menuListItem a {
    color: white;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .mainHeader {
    height: 7rem;
  }

  .leftSign h3 {
    scale: 2.5;
    margin-left: 2rem;
  }
  .homePageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 6rem;
    margin-right: 6rem;

    /* border: 1px solid black; */
    margin-top: 14rem;
    padding: 2rem 0;
  }

  .rightSideHome {
    display: none;
  }

  .leftSideHome {
    width: 100%;
    /* border: 1px solid green; */
  }
  .introPhoto {
    width: 37rem;
    /* border: 1px solid yellow; */
    margin: auto;
  }
  .introPhoto img {
    width: 100%;
    height: auto;
  }

  .introGreeting {
    margin: auto;
  }
  .introGreeting h1 {
    font-size: 6rem;
    background-color: var(--theme-second-color);
    width: 46rem;
    text-align: center;
    color: white;
  }
  .introGreeting h3 {
    font-size: 3.5rem;
  }
  .introGreeting h4 {
    font-size: 2.5rem;
  }

  .aboutHeader h1 {
    font-size: 5rem;
  }

  .quoteAbout {
    margin: 0 2rem;
  }

  .quoteAbout p {
    font-size: 2.4rem;
  }

  .aboutMain {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutPhoto {
    display: none;
  }

  .aboutParagraphSection {
    margin-left: 0;
  }
  .aboutParagraphSection p {
    font-size: 2.5rem;
    font-weight: bold;
  }

  /*Projects section*/

  .ProjectSection {
  }
  .projectsHeader h1 {
    font-size: 5rem;
  }

  .projectsIconPhoto {
    width: 80%;
  }

  .projectsIconPhoto img {
    width: 55%;
  }

  .projectsIntroPar {
    padding: 0 2rem;
  }

  .projectsIntroPar p {
    font-size: 2.4rem;
  }

  .projectsList ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0rem;
    justify-content: center;
    place-items: center;
    margin-bottom: 0rem;
  }

  /*single project*/
  .single-project-container {
    width: 85%;
    font-size: 2.4rem;
    scale: 0.8;
  }
  .single-project-container img {
    width: 100%;
  }

  .single-project-details {
    padding: 2rem 2rem;
  }
  .single-project-par p {
    font-size: var(--fontSecondLevelforResDevices);
  }

  .single-project-actions {
    gap: 3rem;
  }

  .single-project-actions button {
    height: 100%;
    padding: 1rem 1.5rem;
    font-size: 2.4rem;
  }

  /*contact section*/

  .contactMeSection {
    margin-top: 0rem;
    height: 100%;
  }

  .contactMeHeader {
    margin-top: 10rem;
  }
  .contactMeHeader h1 {
    font-size: 5rem;
  }

  .contactMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
  }

  .contactInfo p {
    font-size: 2.5rem;
    margin-bottom: 5rem;
  }

  .leftSideContactMain {
    width: 80%;
    margin: 0 4rem;
  }

  .contactIcons {
    gap: 7rem;
    margin-bottom: 2rem;
  }
  .linkedInIcon,
  .githubIcon {
    scale: 3;
  }

  /*contact form*/

  .formNameAndEmail {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .formNameAndEmail input {
    width: 100%;
  }

  .formSubjectAndMessage {
    gap: 2rem;
  }
  .formSubjectAndMessage input,
  .formSubjectAndMessage textarea {
    width: 100%;
  }

  .rightSideContactMain form input,
  .rightSideContactMain form textarea {
    font-size: 2.5rem;
    width: 100%;
  }

  .rightSideContactMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0rem;
  }

  .rightSideContactMain form {
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding: 2rem; */
  }

  .rightSideContactMain form input {
    padding: 1rem;
  }

  .rightSideContactMain form textarea {
    height: 15rem;
    width: 100%;
    padding: 0.5rem;
  }

  .formActions button {
    width: fit-content;
    padding: 1rem;
    font-size: 2.4rem;
  }

  .footerSection {
    height: 8rem;
    text-align: center;
    font-size: var(--fontFourthLevelforResDevices);
  }

  .footerSection span {
    padding: 0 1rem;
  }

  /*This is for modal*/

  .modal {
    width: 80%;
    padding: 1rem 0;
    box-sizing: border-box;
    /* width: clamp(50%, 700px, 90%); */

    /* height: min(25%, 400px); */
  }

  .modalHeader h3 {
    font-size: 2.4rem;
  }
  .modalBody p {
    font-size: 2.2rem;
    padding: 0 1.5rem;
    text-align: center;
  }

  .modalActions button {
    width: 9rem;

    font-size: 2rem;
    padding: 0.5rem;
  }

  .modalActions {
    margin-bottom: 1rem;
  }

  .titleNav {
    display: none;
  }

  .rightNavIcon {
    display: block;
  }

  .menuList {
    list-style: none;
  }
  .menuListItem {
    font-size: 6vh;
  }

  .menuListItem a {
    color: white;
  }
  .titleNav {
    display: none;
  }

  .rightNavIcon {
    display: block;
  }

  .menuList {
    list-style: none;
  }
  .menuListItem {
    font-size: 6vh;
  }

  .menuListItem a {
    color: white;
  }
}
